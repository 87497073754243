// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "../Styles/Referral.css"; // Create and link this CSS file for custom styles
// import { FaCopy, FaShareAlt } from "react-icons/fa"; // Icons for copy and share buttons

// function Referral() {
//   const [referralLink, setReferralLink] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [copySuccess, setCopySuccess] = useState(false);

//   // Fetch the referral link when the component mounts
//   useEffect(() => {
//     const fetchReferralLink = async () => {
//       setLoading(true);
//       try {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(
//           "https://api.ijeaweleinvest.com/users/get-referral-link/",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setReferralLink(response.data.referral_link);
//       } catch (error) {
//         console.error("Error fetching referral link:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchReferralLink();
//   }, []);

//   // Copy referral link to clipboard
//   const handleCopyLink = () => {
//     navigator.clipboard.writeText(referralLink).then(() => {
//       setCopySuccess(true);
//       setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
//     });
//   };

//   // Placeholder for share functionality
//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: "Referral Link",
//         text: "Join me on IjeaweleInvest with this link!",
//         url: referralLink,
//       }).catch((error) => console.error("Error sharing:", error));
//     } else {
//       alert("Sharing is not supported on this device.");
//     }
//   };

//   return (
//     <div className="referral-container">
//       <h2>Your Referral Code</h2>
//       {loading ? (
//         <p>Loading...</p>
//       ) : (
//         <div className="referral-box">
//           <p className="referral-link">{referralLink}</p>
//           <button className="copy-button" onClick={handleCopyLink}>
//             <FaCopy /> {copySuccess ? "Copied!" : "Copy Link"}
//           </button>
//           <button className="share-button" onClick={handleShare}>
//             <FaShareAlt /> Share Link
//           </button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Referral;


import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Styles/Referral.css"; // Create and link this CSS file for custom styles
import { FaCopy, FaShareAlt } from "react-icons/fa"; // Icons for copy and share buttons

function Referral() {
  const [referralCode, setReferralCode] = useState(""); // State to hold only the referral code
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);

  // Fetch the referral link when the component mounts
  useEffect(() => {
    const fetchReferralCode = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.ijeaweleinvest.com/users/get-referral-link/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Extract the referral code from the referral link
        const link = response.data.referral_link;
        const code = link.split("referral_code=")[1]; // Get the part after 'referral_code='
        setReferralCode(code);
      } catch (error) {
        console.error("Error fetching referral link:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferralCode();
  }, []);

  // Copy referral code to clipboard
  const handleCopyCode = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
    });
  };

  // Share referral code or full link
  const handleShare = () => {
    const shareText = `Join me on IjeaweleInvest with my referral code: ${referralCode}`;
    if (navigator.share) {
      navigator
        .share({
          title: "Referral Code",
          text: shareText,
        })
        .catch((error) => console.error("Error sharing:", error));
    } else {
      // Fallback for devices that do not support the Web Share API
      alert(
        `Sharing is not supported on this device. Copy this code instead: ${referralCode}`
      );
    }
  };

  return (
    <div className="referral-container">
      <h2>Your Referral Code</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="referral-box">
          <p className="referral-code">{referralCode}</p> {/* Display the referral code */}
          <button className="copy-button" onClick={handleCopyCode}>
            <FaCopy /> {copySuccess ? "Copied!" : "Copy Code"}
          </button>
          <button className="share-button" onClick={handleShare}>
            <FaShareAlt /> Share Code
          </button>
        </div>
      )}
    </div>
  );
}

export default Referral;
