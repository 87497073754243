// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import TextInput from "../Components/TextInput";
// import PasswordInput from "../Components/PasswordInput";
// import axios from "axios";
// import { RevolvingDot } from "react-loader-spinner";
// import "../Styles/SignUp.css";

// function SignUp() {
//   const [isAnimated, setIsAnimated] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState(""); // Error message state
//   const [userName, setUserName] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorUserName, setErrorUserName] = useState("");
//   const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
//   const [errorEmail, setErrorEmail] = useState("");
//   const [errorPassword, setErrorPassword] = useState("");
//   const [userNameColor, setUserNameColor] = useState("");
//   const [phoneNumberColor, setPhoneNumberColor] = useState("");
//   const [emailColor, setEmailColor] = useState("");
//   const [passwordColor, setPasswordColor] = useState("");
  
//   const navigate = useNavigate();

//   // Page animation
//   useEffect(() => {
//     setIsAnimated(true);
//   }, []);

//   // Redirect authenticated users to dashboard
//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       navigate("/overview");
//     }
//   }, [navigate]);

//   // Validate sign-up function
// function validate(e) {
//   e.preventDefault();
//   setIsLoading(true);
//   setErrorMessage(""); // Reset error message

//   // Validate form fields
//   if (/^[a-zA-Z'-]+ [a-zA-Z'-]+$/.test(userName) && userName.length > 2) {
//     setErrorUserName("");
//     setUserNameColor("green");
//   } else {
//     setErrorUserName("Invalid name format");
//     setUserNameColor("red");
//   }

//   if (phoneNumber.length > 10 || phoneNumber.includes("+/-")) {
//     setErrorPhoneNumber("");
//     setPhoneNumberColor("green");
//   } else {
//     setErrorPhoneNumber("Invalid phone number format");
//     setPhoneNumberColor("red");
//   }

//   const emailRegex = /^\w+@\w+\.\w+$/;
//   if (emailRegex.test(email)) {
//     setErrorEmail("");
//     setEmailColor("green");
//   } else {
//     setErrorEmail("Invalid email format");
//     setEmailColor("red");
//   }

//   if (
//     password.length >= 8 &&
//     /[a-z]/.test(password) &&
//     /[A-Z]/.test(password) &&
//     /[0-9]/.test(password) &&
//     /[^a-zA-Z0-9]/.test(password)
//   ) {
//     setErrorPassword("");
//     setPasswordColor("green");
//   } else {
//     setErrorPassword(
//       "Password must be at least 8 characters, including lowercase, uppercase, number, and special character."
//     );
//     setPasswordColor("red");
//   }

//   // Submit the form if all fields are valid
//   if (!errorUserName && !errorPhoneNumber && !errorEmail && !errorPassword) {
//     const fullName = userName.split(" ");
//     const firstName = fullName[0];
//     const lastName = fullName.slice(1).join(" ");
//     const registrationData = {
//       first_name: firstName,
//       last_name: lastName,
//       phone_number: phoneNumber,
//       email: email,
//       password: password,
//     };

//     // Send registration data to backend
//     axios
//       .post("https://api.ijeaweleinvest.com/users/register/", registrationData)
//       .then((response) => {
//         setIsLoading(false);
//         if (response.status === 201) {
//           localStorage.setItem("email", email);
//           navigate("/otpverification", { state: { email: email } });
//         }
//       })
//       .catch((error) => {
//         setIsLoading(false);

//         // Determine error type
//         if (error.response) {
//           // Handle validation errors from server
//           const serverError = error.response.data;
//           if (serverError.email || error.response.status === 500) {
//             setErrorEmail("Email or Phone number already in use. Try a different email.");
//           } else if (serverError.phone_number || error.response.status === 500) {
//             setErrorPhoneNumber("Email or Phone number is invalid or already used.");
//           } else {
//             setErrorMessage("Registration failed. Please try again.");
//           }
//         } else if (error.request) {
//           // Network error: no response received
//           setErrorMessage("Network error. Check your connection and try again.");
//         } else {
//           // Other errors (unexpected)
//           setErrorMessage("An unexpected error occurred. Please try again later.");
//         }

//         console.error("Registration error:", error.message);
//       });
//   } else {
//     setIsLoading(false);
//   }
// }

//   return (
//     <div className="signup-container no-topnav-page">
//       <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
//         <h1 className="hello-text">Hello</h1>
//         <h2 className="welcome-text">Create An Account With Ijeawele</h2>
//       </div>
//       <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
//         <div className="form-card">
//           <h2>Sign Up</h2>
//           <p>Welcome back to Ijeawele Investments</p>
//           <form>
//             <TextInput
//               label="Full Name"
//               type="text"
//               id="fullName"
//               placeholder="Enter your first name and last name"
//               required
//               style={{ borderColor: userNameColor }}
//               value={userName}
//               onChange={(e) => setUserName(e.target.value)}
//               error={errorUserName}
//             />
//             <TextInput
//               label="Phone Number"
//               type="tel"
//               id="phoneNumber"
//               placeholder="Enter your phone number"
//               required
//               style={{ borderColor: phoneNumberColor }}
//               value={phoneNumber}
//               onChange={(e) => setPhoneNumber(e.target.value)}
//               error={errorPhoneNumber}
//             />
//             <TextInput
//               label="Email"
//               type="email"
//               id="email"
//               placeholder="Enter your email"
//               required
//               style={{ borderColor: emailColor }}
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               error={errorEmail}
//             />
//             <PasswordInput
//               id="password"
//               placeholder="Enter your password"
//               label="Password"
//               style={{ borderColor: passwordColor }}
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               error={errorPassword}
//             />

//             {isLoading && (
//               <RevolvingDot
//                 type="RevolvingDot"
//                 color="#00BFFF"
//                 height={5}
//                 width={5}
//                 timeout={3000}
//               />
//             )}

//             {errorMessage && <p className="error-message">{errorMessage}</p>}

//             <button type="submit" onClick={validate}>
//               Sign up
//             </button>
//           </form>

//           <p className="create-account-text">
//             Already have an Account? <Link to="/signin">Sign In Here!</Link>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SignUp;



import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../Components/TextInput";
import PasswordInput from "../Components/PasswordInput";
import axios from "axios";
import { RevolvingDot } from "react-loader-spinner";
import "../Styles/SignUp.css";

function SignUp() {
  const [isAnimated, setIsAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState(""); // New field for referral code
  const [errorUserName, setErrorUserName] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [userNameColor, setUserNameColor] = useState("");
  const [phoneNumberColor, setPhoneNumberColor] = useState("");
  const [emailColor, setEmailColor] = useState("");
  const [passwordColor, setPasswordColor] = useState("");

  const navigate = useNavigate();

  // Page animation
  useEffect(() => {
    setIsAnimated(true);
  }, []);

  // Redirect authenticated users to dashboard
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/overview");
    }
  }, [navigate]);

  // Validate sign-up function
  function validate(e) {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(""); // Reset error message

    // Validate form fields
    if (/^[a-zA-Z'-]+ [a-zA-Z'-]+$/.test(userName) && userName.length > 2) {
      setErrorUserName("");
      setUserNameColor("green");
    } else {
      setErrorUserName("Invalid name format");
      setUserNameColor("red");
    }

    if (phoneNumber.length > 10 || phoneNumber.includes("+/-")) {
      setErrorPhoneNumber("");
      setPhoneNumberColor("green");
    } else {
      setErrorPhoneNumber("Invalid phone number format");
      setPhoneNumberColor("red");
    }

    const emailRegex = /^\w+@\w+\.\w+$/;
    if (emailRegex.test(email)) {
      setErrorEmail("");
      setEmailColor("green");
    } else {
      setErrorEmail("Invalid email format");
      setEmailColor("red");
    }

    if (
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[^a-zA-Z0-9]/.test(password)
    ) {
      setErrorPassword("");
      setPasswordColor("green");
    } else {
      setErrorPassword(
        "Password must be at least 8 characters, including lowercase, uppercase, number, and special character."
      );
      setPasswordColor("red");
    }

    // Submit the form if all fields are valid
    if (!errorUserName && !errorPhoneNumber && !errorEmail && !errorPassword) {
      const fullName = userName.split(" ");
      const firstName = fullName[0];
      const lastName = fullName.slice(1).join(" ");
      const registrationData = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        email: email,
        password: password,
        referral_link: referralCode
          ? `https://ijeaweleinvest.com/signup?referral_code=${referralCode}`
          : undefined, // Include referral link only if provided
      };

      // Send registration data to backend
      axios
        .post("https://api.ijeaweleinvest.com/users/register/", registrationData)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 201) {
            localStorage.setItem("email", email);
            navigate("/otpverification", { state: { email: email } });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          // Determine error type
          if (error.response) {
            // Handle validation errors from server
            const serverError = error.response.data;
            if (serverError.email || error.response.status === 500) {
              setErrorEmail("Email or Phone number already in use. Try a different email.");
            } else if (serverError.phone_number || error.response.status === 500) {
              setErrorPhoneNumber("Email or Phone number is invalid or already used.");
            } else {
              setErrorMessage("Registration failed. Please try again.");
            }
          } else if (error.request) {
            // Network error: no response received
            setErrorMessage("Network error. Check your connection and try again.");
          } else {
            // Other errors (unexpected)
            setErrorMessage("An unexpected error occurred. Please try again later.");
          }

          console.error("Registration error:", error.message);
        });
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className="signup-container no-topnav-page">
      <div className={`left-content ${isAnimated ? "slide-in-left" : ""}`}>
        <h1 className="hello-text">Hello</h1>
        <h2 className="welcome-text">Create An Account With Ijeawele</h2>
      </div>
      <div className={`right-form ${isAnimated ? "slide-in-right" : ""}`}>
        <div className="form-card">
          <h2>Sign Up</h2>
          <p>Welcome back to Ijeawele Investments</p>
          <form>
            <TextInput
              label="Full Name"
              type="text"
              id="fullName"
              placeholder="Enter your first name and last name"
              required
              style={{ borderColor: userNameColor }}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              error={errorUserName}
            />
            <TextInput
              label="Phone Number"
              type="tel"
              id="phoneNumber"
              placeholder="Enter your phone number"
              required
              style={{ borderColor: phoneNumberColor }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              error={errorPhoneNumber}
            />
            <TextInput
              label="Email"
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              style={{ borderColor: emailColor }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorEmail}
            />
            <PasswordInput
              id="password"
              placeholder="Enter your password"
              label="Password"
              style={{ borderColor: passwordColor }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errorPassword}
            />
            {/* New optional referral field */}
            <TextInput
              label="Referral Code (Optional)"
              type="text"
              id="referralCode"
              placeholder="Enter referral code (if any)"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />

            {isLoading && (
              <RevolvingDot
                type="RevolvingDot"
                color="#00BFFF"
                height={5}
                width={5}
                timeout={3000}
              />
            )}

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button type="submit" onClick={validate}>
              Sign up
            </button>
          </form>

          <p className="create-account-text">
            Already have an Account? <Link to="/signin">Sign In Here!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
