import "../Styles/InvestmentBanner.css";

const InvestmentBanner = () => {
  return (
    <div className="investment-banner">
      <h3>
        <span className="banner-percent">Up to 50% </span> ROI <br /> on Hire Purchase
      </h3>
    </div>
  );
};

export default InvestmentBanner;
